<template>
<div class="container-lg bg-white pb-2">
  <div class="row">
		<div class="col align-self-center">
			<div class="mt-4 p-3  bg-white rounded">
				<h1 class="display-4"> <img src="https://babitesvidusskola.lv/wp-content/themes/bvsk/img/Oranzais-logo.png"> Babītes vidusskolas sistēmu monitors</h1>
				<p class="lead">Sistēmas monitors ļauj monitorēt digitālo sistēmu darbību un to atbildes laikus.</p>
        <small>Statistika apkopota par pēdējām {{ cik_pedejas_dienas_raada }} dienām.</small>
				<hr class="my-4">
			</div>
		</div>
	</div>
  <div class="row" v-if="remonti.length > 0">
    <h2><i class="bi bi-wrench"></i> Sistēmu tehniskie darbi </h2>
    <template v-for="r in remonti" :key="'logsRemonts_'+r.virsraksts">
      <div class="col">
        <div :class="'card' + ((r.sobrid_notiek == 1) ? ' text-dark bg-warning':'')">
          <div class="card-header fw-bold">
            <i class="bi bi-dot"></i> {{ r.virsraksts }} 
          </div>
          <div class="card-body">
            <p class="card-text">
              <i class="bi bi-info-circle"></i> Informācija: {{ r.aprakstrs }}<br>
              <i class="bi bi-clock-history"></i> Laika periods: {{ r.sakums }} - {{ r.beigas }} {{ ((r.sobrid_notiek == 1) ? ' [ŠOBRĪD] ':' [IEPLĀNOTS] ') }}<br>
              <i class="bi bi-card-checklist"></i> Iesaistītās sistēmas: {{ r.iesaistite_domeni.join(', ') }}
            </p>
          </div>
        </div>
      </div>
    </template>
     
  </div>
  <hr  v-if="remonti.length > 0">
  <div class="row">
			<div class="col align-self-center">
			<h2><i class="bi bi-cloud-check"></i> Sistēmu monitoru pārbaudes pēc datumiem  
				<div class="spinner-border text-primary" role="status" v-if="loading">
								<span class="visually-hidden">Loading...</span>
				</div> 
			</h2>
      <div class="alert alert-danger" v-if="error_iegustot_datus"> {{ error_iegustot_datus }}</div>
      <div class="accordion accordion-flush p-2" id="accordionFlushExample">
        <template v-for="d in dati" :key="'logs3_'+d.id">
              <div class="accordion-item">
                <h2 class="accordion-header" :id="'flush-headingOne'+d.id">
                  <button class="accordion-button collapsed d-flex justify-content-between align-items-center " type="button" data-bs-toggle="collapse" :data-bs-target="'#add'+d.id" aria-expanded="false" :aria-controls="'#add'+d.id">
                    <p class="h5 mb-0 flex-grow-1">
                      <span  v-if="d.shodien_ir_errors == 1" style="color: red;font-size: 20px;">●</span><span  v-else style="color: green;font-size: 20px;">●</span>
                      {{d.lielaisnosaukums}}</p>
                    <span class="h5 mb-0 pr-2 me-5">
                      <span class="badge bg-success" v-if="d.shodien_ir_errors == 0"><i class="bi bi-check-circle"></i> Darbojas</span>
                      <span class="badge bg-warning text-dark" v-else><i class="bi bi-exclamation-triangle"></i> Ir novērotas problēmas</span>
                    </span>
                  </button>
                </h2>
                 
                <div :id="'add'+d.id" class="accordion-collapse collapse" :aria-labelledby="'flush-headingOne'+d.id">
                    <div class="accordion-body">
                      <p><i class="bi bi-graph-up"></i> Sistēmas darbības procents {{d.procents}}%</p>
                      <div class="row">
                        <div class="col">
                            <h3>Sistēmas pārbaudes datumi un iznākumi</h3>
                            <p><i class="bi bi-calendar3"></i> Pēdējās {{ cik_pedejas_dienas_raada }} dienas</p>
                            <ul class="list-group list-group-flush">
                              <template v-for="l in d.zurnala_datumi.slice().reverse()" :key="'logs1_'+l.datums">
                                <li :class="'list-group-item ' + ((l.ir_errori == 1)? 'list-group-item-warning fw-bold':'') ">
                                        {{ l.datums }} 
                                        <span  v-if="l.ir_errori == 1"><i class="bi bi-exclamation-triangle"></i> Novērotas sistēmas darbības problēmas</span>
                                        <span  v-else><i class="bi bi-check-circle"></i> Problēmas netika konstatētas</span>
                                </li>
                              </template>
                            </ul>
                        </div>
                        <div class="col">
                          <h3>Sistēmas pārbaudes laikā konstatētās kļūdas</h3>
                          <ul class="timeline" v-if="d.kludas.length > 0">
                            <template v-for="lg in d.kludas" :key="'logs2_'+lg.ts">
                              <li>
                                <span class="float-right">{{lg.datums}}</span>
                                <p>Kļūda: {{dabu_statuskodainfo(lg.kludas_kods)}} ({{lg.kludas_kods}})</p>
                              </li>
                            </template>
                          </ul>
                          <span v-else>
                            Incidentu nav.
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </template>
        </div>
		</div>
		<div class="row pb-4 align-items-start" v-if="dati.length > 0">
			<hr>
			<div class="col align-self-center">
				<h2><i class="bi bi-graph-up"></i> Vidējie sistēmu atbildes laiki sekundēs pa datumiem </h2>
        <div class="m-5"><apexchart type="line" :options="options" :series="series"></apexchart></div> 
			</div>
		
			</div>
		</div>
</div>
<footer id="sticky-footer" class="py-4 text-white-50">
    <div class="container text-center">
      <small style="color: white;font-weight: bold;font-size: 16px;">&copy; Kristaps Muižnieks, Babītes vidusskola  </small>
    </div>
  </footer>

</template>

<script>
import axios from 'axios';
import VueApexCharts from "vue3-apexcharts";
export default {
  name: 'App',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading:false,
			dati: [],	
      remonti: [],
      cik_pedejas_dienas_raada: 0,
      error_iegustot_datus: null,
      options: {
        yaxis: {
          labels: {
            formatter: function (value) {
              if( value == undefined) value = 0;
              return value.toFixed(2) + " s";
            }
          },
          tickAmount: 15,
          title: {
                text: 'Sistēmas vidējais atbildes laiks sekundēs'
          }
        },
        xaxis:{
          title: {
                text: 'Sistēmas pārbaudes datums'
          }
        },
        stroke: {
            curve: 'smooth',
          },
          markers: {
            size: 1,
          },
          
        chart: {
          id: 'sistemu_darbibas_atbildes_laiki',
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          },
           
        },
      },
      series: []
    }
  },
  methods: {
    dabu_statuskodainfo:function(kods){
      let masivs = {"0":"Timeout","100":"Continue","101":"Switching Protocols","200":"OK","201":"Created","202":"Accepted","203":"Non-Authoritative Information","204":"No Content","205":"Reset Content","206":"Partial Content","300":"Multiple Choices","301":"Moved Permanently","302":"Temporary redirect","303":"See Other","304":"Not Modified","305":"Use Proxy","306":"(Unused)","307":"Temporary Redirect","400":"Bad Request","401":"Unauthorized","402":"Payment Required","403":"Forbidden","404":"Not Found","405":"Method Not Allowed","406":"Not Acceptable","407":"Proxy Authentication Required","408":"Request Timeout","409":"Conflict","410":"Gone","411":"Length Required","412":"Precondition Failed","413":"Request Entity Too Large","414":"Request-URI Too Long","415":"Unsupported Media Type","416":"Requested Range Not Satisfiable","417":"Expectation Failed","500":"Internal Server Error","501":"Not Implemented","502":"Bad Gateway","503":"Service Unavailable","504":"Gateway Timeout","505":"HTTP Version Not Supported"};
      return masivs[kods];
    },
    visa_info: function(){
        let self = this;
        this.loading =true;
        self.error_iegustot_datu = null;
        axios.post("https://status.bvsk.lv/ajax.php").then((response) => {
            self.dati = response.data.sistemu_dati;
            self.remonti = response.data.tech_darbi;
            self.loading = false;
            if(typeof response.data.informacija.cik_pedejas_dienas_raada != undefined){
              self.cik_pedejas_dienas_raada = response.data.informacija.cik_pedejas_dienas_raada;
            }
            self.dati_grafikam();
        }).catch(function (error) {
          if (error.response)  self.error_iegustot_datus = "Nav iegūti dati, mēģiniet vēlāk!";
        });             
		},
    dati_grafikam:function(){
      this.series = [];
      let d = null;
      let l = null;
      for(d of this.dati){
        let laiki = [];
        for(l of d.merijuma_laiki) laiki.push({x:l.datums,y:l.laiks});
        this.series.push({"name":d.lielaisnosaukums,data:laiki});
      }
    }
  },
  mounted() {
    this.visa_info();
    window.setInterval(() => {
      this.visa_info()
    }, 30000);
  }
}
</script>

<style scoped>
.good {
    background: green;
  }
  .bad {
    background: red;
  }
  .remonts {
    background: orange;
  }
  td.statuscell {
    border: 1px solid white;
    max-width: 25px;
    padding: 11px;
    color: #ffffff8f;
  }
  div.uzraksts {
    padding: 3px;
    
    font-weight: bold;
    margin: 10px;
  }
  .jumbotron {
  
    background-color: #ffffff;
  
  }
  li.list-group-item.pavidu {
    text-align: center;
    background: #FFEB3B;
    font-weight: bold;
    font-size: 1.3rem;
}
.container-fluid {
    width: 90%;
}
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #F44336;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
.layout-content.status .shared-partial.uptime-90-days-wrapper .legend .spacer {
    background: #9ba4b1;
    opacity: 0.3;
}
.shared-partial.uptime-90-days-wrapper .legend .spacer {
    flex: 1;
    margin: 0.75rem 1rem 0 1rem;
    height: 1px;
    background: #aaa;
    opacity: 0.3;
}
.rotate {
        -webkit-transform: rotate(-180deg);        
        -moz-transform: rotate(-180deg);            
        -ms-transform: rotate(-180deg);         
        -o-transform: rotate(-180deg);         
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
      font-size: 15px;
    }
</style>
